import { PostTemplateProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx21WeekEmaChartTemplate from '@/templates/crypto/xxx-21-week-ema-chart';

export default function Bitcoin21WeekEmaChart({ location }: PostTemplateProps) {
  return (
    <Xxx21WeekEmaChartTemplate
      coinAbbreviation="BTC"
      coinFullName="Bitcoin"
      token={Token.BTCUSDT}
      location={location}
      articleId={ArticleList.BITCOIN_21_WEEK_EMA_CHART}
    ></Xxx21WeekEmaChartTemplate>
  );
}
